<div class="messages-container">
    <div class="message-wrapper" *ngFor="let message of messages.getMessages(); let i = index; last as isLast; first as isFirst">
        <div *ngIf="message.author === 'ai'" class="ai-message">
            <div class="image-container">
                <img [src]="avatarIcon" class="avatar-icon" alt="AI"/>
            </div>
            <div class="ai-text-message">
                <p [dir]="message.direction" *ngIf="message.content"> {{ message.content}} </p>
                <div *ngIf="message.attachments && message.attachments.length > 0" class="buttons-wraper">
                    <button
                        *ngFor="let attachment of message.attachments"
                        type="button"
                        class="ai-content-button"
                        data-bs-toggle="modal"
                        data-bs-target="#idModal"
                        (click)="emitMediaType(attachment.type, attachment.url)">
                        <div class="media-wrapper">
                            <div class="image-wrapper">
                                <img *ngIf="attachment.type=='image'" [src]="attachment.url" alt="AI Image">
                            </div>
                            <div *ngIf="attachment.type=='video'" class="video-wrapper">
                                <video [src]="attachment.url" ></video>
                                <div class="play-video-icon">
                                    <img alt="Play video icon">
                                </div>
                            </div>
                            <button *ngIf="attachment.type=='document'" class="avatar-pdf-button">
                                <img alt="Pdf icon"/>
                            </button>
                        </div>
                    </button>
                </div>
            </div>
            <div
                class="message-feedback"
                *ngIf="(message.feedback === true || message.feedback === false) || (isLast && !isFirst)"
            >
                <div class="feedback-container">
                    <button
                        *ngIf="message.feedback === true || message.feedback === undefined"
                        type="button"
                        class="avatar-thumbs-up-button"
                        (click)="positiveReaction(i)"
                        (mouseover)="isHoveredThumbUp = true"
                        (mouseout)="isHoveredThumbUp = false"
                    >
                        <app-thumb-up [thumbsColor]="handleThumbUpColor(message, isHoveredThumbUp)"></app-thumb-up>
                    </button>
                    <button
                        *ngIf="message.feedback === false || message.feedback === undefined"
                        type="button"
                        class="avatar-thumbs-down-button"
                        (click)="negativeReaction(i)"
                        (mouseover)="isHoveredThumbDown = true"
                        (mouseout)="isHoveredThumbDown = false"
                    >
                        <app-thumb-down [thumbsColor]="handleThumbDownColor(message, isHoveredThumbDown)"></app-thumb-down>
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="message.author === 'client'" class="client-message">
            <div class="client-text-message">
                <p *ngIf="!message.attachments || message.attachments.length === 0" [dir]="message.direction"> {{ message.content }} </p>
                <div *ngIf="message.attachments && message.attachments.length > 0" class="buttons-wraper">
                    <button
                        *ngFor="let attachment of message.attachments"
                        type="button"
                        class="ai-content-button"
                        data-bs-toggle="modal"
                        data-bs-target="#idModal"
                        (click)="emitMediaType(attachment.type, attachment.url)">
                        <div class="media-wrapper">
                            <div class="image-wrapper">
                                <img *ngIf="attachment.type=='image'" [src]="attachment.url" alt="AI Image">
                            </div>
                            <button *ngIf="attachment.type=='document'" class="avatar-pdf-button">
                                <img alt="Pdf icon"/>
                            </button>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
