import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-microphone',
    template: `
        <svg [attr.width]="width" [attr.height]="height" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="4.92617" y="0.85" width="7.99231" height="12.3" rx="3.99615" [attr.stroke]="strokeColor" stroke-width="1.7"/>
            <path d="M16.9996 9.15332C16.9996 13.6141 13.3834 17.2302 8.92263 17.2302C4.46186 17.2302 0.845703 13.6141 0.845703 9.15332" [attr.stroke]="strokeColor" stroke-width="1.7" stroke-linecap="round"/>
            <path d="M8.92383 17.2305V20.9997" [attr.stroke]="strokeColor" stroke-width="1.7" stroke-linecap="round"/>
        </svg>
    `,
})
export class MicrophoneSvgComponent {
    @Input() strokeColor: string = 'white';
    @Input() height: string = '22px';
    @Input() width: string = '18px';
}
