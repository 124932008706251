<div
    class="modal fade"
    [id]="idModal"
    [attr.aria-labelledby]="labelModal"
    aria-hidden="true"
    tabindex="-1"
>
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body p-0">
                <div class="position-container">
                    <button type="button" class="modal-button-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
                        <img alt="Close modal icon">
                    </button>
                    <div>
                        <img *ngIf="mediaType=='image'" class="modal-image" [src]="mediaSource" alt="Modal Image">
                        <div class="video-container">
                            <video
                                #videoModal
                                *ngIf="mediaType=='video'"
                                [src]="mediaSource"
                                playsinline controls></video>
                        </div>
                        <div *ngIf="mediaType=='document'" class="document-shower">
                            <embed [src]="mediaSource | safe" type="application/pdf"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
