import {Component, OnDestroy, OnInit} from '@angular/core';
import { Subscription } from 'rxjs';

import { ConfigService } from 'app/services/config.service';
import { LanguageService } from 'app/services/language.service';
import { ComponentAbstract } from 'app/components/component.abstract';
import { InteractionService } from "app/services/interaction.service";

@Component({
    selector: 'app-languages',
    templateUrl: './languages.component.html',
    styleUrl: './languages.component.scss'
})
export class LanguagesComponent extends ComponentAbstract implements OnInit, OnDestroy {
    dropDownVisible: boolean = false;
    selectedLanguage: any;
    languages: any[] = [];
    isInteractionAllowed: boolean = false;

    languageSubscription!: Subscription
    translateSubscription!: Subscription;
    interactionSubscription!: Subscription;

    constructor(
        config: ConfigService,
        private language: LanguageService,
        private interaction: InteractionService
    ){
        super(config);
    }

    ngOnInit(): void {
        this.languages = this.config.getLanguages();

        this.languageSubscription = this.language.selectedLanguage$.subscribe(selectedLanguage => {
            this.selectedLanguage = selectedLanguage;
        });

        this.interactionSubscription = this.interaction.getInteractionMode().subscribe(interactionAllowed => {
            this.isInteractionAllowed = interactionAllowed;
        });

        this.translateSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            const translate = this.language.getDesignTranslation(selectedLanguage.locale)
            this.languages.forEach(language => {
                language.name = translate.typography.languageNames[language.locale];
            });
        });
    }

    getClass(language: any) {
        return {
            'selected': language === this.selectedLanguage,
            'active': language === this.selectedLanguage
        };
    }

    toggleDropdown() {
        this.dropDownVisible = !this.dropDownVisible;
    }

    toggleLanguage(selectedMenuLanguage: any) {
        this.selectedLanguage = selectedMenuLanguage;
        this.language.setLanguage(selectedMenuLanguage.locale);
    }

    ngOnDestroy(): void {
        this.interactionSubscription.unsubscribe();
    }
}
