<div *ngIf="isVisible" class="avatar-drag-drop-zone">
    <div *ngIf="!hasUploadFiles()" class="avatar-drag-drop-container">
        <div class="avatar-browse-container">
            <div class="avatar-browse-title"
                (dragover)="onDragOver($event)"
                (dragleave)="onDragOver($event)"
                (drop)="onFileDrop($event)"
            >
                <span [dir]="textDirection">{{ dragDropText }}</span>
            </div>
            <div class="avatar-browse-button">
                <button class="browse-button" type="button" (click)="openFileInput()">{{ buttonBrowseText }}</button>
            </div>
            <div class="avatar-browse-note">
                <p [dir]="textDirection" class="browse-text">{{ supportedFormatsText }}</p>
                <p [dir]="textDirection" class="browse-text">{{ maxFileSizeText }}</p>
                <input type="file" class="input-file" #fileInput id="file"
                    (change)="onFileSelect($event)"
                    accept=".jpg,.png,.txt,.pdf" />
            </div>
        </div>
    </div>
    <div *ngIf="hasUploadFiles()" class="avatar-upload-files-container" >
        <div class="upload-list">
            <div class="upload-item">
                <img class="file-icon" alt="File icon" />
                <p [dir]="textDirection" class="file-name">{{ fileItem.name }}</p>
                <p class="file-size">{{ fileItem.size }}</p>
                <img class="delete-icon-done" alt="Delete icon" *ngIf="!fileItem.showLoad" tabindex="0" />
                <img class="delete-icon-loading" alt="Delete icon" *ngIf="fileItem.showLoad" tabindex="0" />
            </div>
        </div>
        <button class="avatar-submit-drag-drop"
            type="button"
            [disabled]="fileItem.showLoad"
            [ngClass]="{'disabled-button': fileItem.showLoad}"
            (click)="submitFile(fileItem)">
            {{ buttonText }}
        </button>
    </div>
    <div class="avatar-drag-drop-title">
        <app-attachment width="19px" height="22px" class="attachmentContainer"></app-attachment>
        <p [dir]="textDirection" class="attachementTitle">{{ attachmentText }}</p>
        <button class="avatar-drag-drop-close" type="button" (click)="closeDragDrop()">
            <app-close-svg class="closeContainer" closeColor="rgba(75, 89, 113, 1)"></app-close-svg>
        </button>
    </div>
</div>
