<div *ngIf="isVisible" class="avatar-answer-feedback">
    <div class="avatar-feedback-wrapper">
        <div class="avatar-feedback-title">
            <app-thumb-down class="thumb-icon" width="20px" height="20px" thumbsColor="rgba(248,248,248,1)"></app-thumb-down>
            <p [dir]="textDirection" class="feedbackTitle">{{ feedbackText }}</p>
            <button class="avatar-feedback-close" type="button" (click)="closeFeedback()">
                <app-close-svg class="closeContainer" closeColor="rgba(75, 89, 113, 1)"></app-close-svg>
            </button>
        </div>
        <div class="avatar-feedback-body">
            <textarea [dir]="textDirection" [placeholder]="placeholderText" [(ngModel)]="additionalFeedback"></textarea>
            <ul class="avatar-feedback-reasons">
                <li *ngFor="let checkbox of checkboxes" [dir]="textDirection">
                    <input type="checkbox" [id]="checkbox.id" name="reason-1" [(ngModel)]="checkbox.checked" />
                    <label class="checkboxLabel" [for]="checkbox.id">
                        <span class="avatar-feedback-checkbox">
                            <span class="check-icon"></span>
                        </span>
                    </label>
                    <label [for]="checkbox.id">{{ checkbox.text }}</label>
                </li>
            </ul>
            <button class="avatar-submit-feedback" type="button" (click)="submitNegativeReaction()">{{ buttonText }}</button>
        </div>
    </div>
</div>
