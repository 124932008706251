<div *ngIf="isVisible" class="avatar-init-container">
    <div class="avatar-wrapper">
        <div class="avatar-start-messages" [dir]="textDirection">
            <span> {{ greetingMessages[0] }} </span>
            <span class="start-chat" (click)="onClick()" > {{ greetingMessages[1] }}</span>
        </div>
        <div class="avatar-button-wrapper">
            <button
                [style.background-image]="'url(' + avatarCircleIcon + ')'"
                type="button"
                class="avatar-button-image"
                (click)="onClick()">
            </button>
        </div>
    </div>
</div>
