import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";

import { ConfigService } from 'app/services/config.service';
import { AvatarInteractionService } from 'app/services/avatar-interaction.service';
import { ComponentAbstract } from 'app/components/component.abstract';
import { LanguageService } from "app/services/language.service";

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrl: './avatar.component.scss'
})
export class AvatarComponent extends ComponentAbstract implements OnInit, OnDestroy {
    isVisible: boolean = false;

    visibilitySubscription!: Subscription;

    constructor(
        config: ConfigService,
        private visibility: AvatarInteractionService,
        private language: LanguageService
    ){
        super(config);
    }

    ngOnInit() {
        this.visibilitySubscription = this.visibility.getVisibility('avatar').subscribe(visible => {
            this.isVisible = visible;

            if (this.isVisible) {
                ["avatar-controls", "avatar-shortcuts"].map(key => this.visibility.showComponent(key));
                ["avatar-feedback", "avatar-drag-drop"].map(key => this.visibility.hideComponent(key));
            } else {
                this.visibility.showComponent("avatar-init");
                ["avatar-controls", "avatar-shortcuts"].map(key => this.visibility.hideComponent(key));
            }
        });
    }

    ngOnDestroy() {
        this.visibilitySubscription.unsubscribe();
    }
}
