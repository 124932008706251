import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-thumb-up',
    template: `
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
            <path d="M3.99037 7.2278C3.99037 6.55058 3.44137 6.00158 2.76414 6.00158H1.22622C0.548998 6.00158 0 6.55058 0 7.2278V14.7738C0 15.451 0.548997 16 1.22622 16H2.76414C3.44137 16 3.99037 15.451 3.99037 14.7738V7.2278Z" [attr.fill]="thumbsColor"/>
            <path d="M7.3574 3.89502V0.482585C7.3574 0.21606 7.57346 0 7.83998 0H8.18274C9.55021 0 10.6588 1.10855 10.6588 2.47602V6.7562H13.7867C15.58 6.7562 16.921 8.40289 16.5579 10.159L15.8417 13.6226C15.5699 14.9367 14.4125 15.8793 13.0705 15.8793H5.94253C5.65455 15.8793 5.38165 15.8148 5.13749 15.6994C5.24946 15.4125 5.31091 15.1003 5.31091 14.7738V7.2278C5.31091 7.03148 5.28869 6.84035 5.24664 6.65678C6.46282 6.32711 7.3574 5.21553 7.3574 3.89502Z" [attr.fill]="thumbsColor"/>
        </svg>
    `,
})
export class ThumbUpSvgComponent {
    @Input() thumbsColor: string = '#31405A';
}
