<div *ngIf="isVisible" class="avatar-container">
    <div class="avatar-background-container">
        <div class="avatar-background-image">
            <app-avatar-video-presenter></app-avatar-video-presenter>
            <app-avatar-heading [avatarTab]="'avatar'"></app-avatar-heading>
            <app-avatar-shortcuts></app-avatar-shortcuts>
            <app-avatar-feedback></app-avatar-feedback>
            <app-avatar-drag-drop></app-avatar-drag-drop>
            <app-avatar-conversation-board></app-avatar-conversation-board>
            <app-avatar-controls></app-avatar-controls>
        </div>
    </div>
</div>

<app-modal-content idModal="idModal" labelModal="labelModal"></app-modal-content>
