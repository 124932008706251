import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})

export class AvatarInteractionService {
    private visibilityMap = new Map<string, BehaviorSubject<boolean>>();

    constructor() {}

    private getSubjectForKey(key: string): BehaviorSubject<boolean> {
        if (!this.visibilityMap.has(key)) {
            // If a BehaviorSubject for this key doesn't exist, create it
            this.visibilityMap.set(key, new BehaviorSubject<boolean>(false));
        }
        return this.visibilityMap.get(key)!;
    }

    // Function to show a component
    showComponent(key: string): void {
        const subject = this.getSubjectForKey(key);
        subject.next(true);
    }

    // Function to hide a component
    hideComponent(key: string): void {
        const subject = this.getSubjectForKey(key);
        subject.next(false);
    }

    // Function to get the visibility state Observable of a component
    getVisibility(key: string): Observable<boolean> {
        return this.getSubjectForKey(key).asObservable();
    }

    // Function to toggle visibility of a component
    toggleComponent(key: string): void {
        const subject = this.getSubjectForKey(key);
        subject.next(!subject.value);
    }
}
