import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";

import { ConfigService } from 'app/services/config.service';
import { AvatarInteractionService } from 'app/services/avatar-interaction.service';
import { ComponentAbstract } from 'app/components/component.abstract';
import { LanguageService } from "app/services/language.service";

@Component({
    selector: 'app-avatar-init',
    templateUrl: './avatar-init.component.html',
    styleUrl: './avatar-init.component.scss'
})
export class AvatarInitComponent extends ComponentAbstract implements OnInit, OnDestroy {
    isVisible: boolean = true;
    textDirection!: string;
    greetingMessages: string[] = [ "Hello, I'm here to help!", "Click here to start the chat." ];
    avatarCircleIcon: any;

    visibilitySubscription!: Subscription;
    translateSubscription!: Subscription;
    circleSubscription!: Subscription;

    constructor(
        config: ConfigService,
        private visibility: AvatarInteractionService,
        private language: LanguageService,
    ){
        super(config);
    }

    ngOnInit() {
        this.circleSubscription = this.config.getPresenter().subscribe(background => {
            if (background) { this.avatarCircleIcon = background; }
        });

        this.visibilitySubscription = this.visibility.getVisibility('avatar-init').subscribe(visible => {
            this.isVisible = visible;
        });

        this.translateSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            const translate = this.language.getDesignTranslation(selectedLanguage.locale)
            this.textDirection = this.config.getDirectionFromLanguage(selectedLanguage.locale);

            this.greetingMessages =  translate.typography.greetingMessages;
        });

        this.visibility.showComponent('avatar-init');
    }

    // events
    onClick () {
        this.visibility.showComponent('avatar');
        this.visibility.hideComponent('avatar-init');
    }

    ngOnDestroy() { // This should represent destructor
        this.visibilitySubscription.unsubscribe();
    }
}
