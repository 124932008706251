<div id="landing-container">
    <div class="container-fluid">
        <nav>
            <div class="logo-container">
                <div class="landing-logo">
                    <img [src]="companyLogo" alt="Company Logo" />
                </div>
            </div>
        </nav>
        <div class="landing-marketing">
            <h2 [dir]="textDirection"> {{ welcomeHeader }} </h2>
            <p [dir]="textDirection"> {{ welcomeDescription }} </p>
        </div>
    </div>
    <div class="landing-container">
        <app-avatar></app-avatar>
        <app-avatar-init></app-avatar-init>
    </div>
</div>
