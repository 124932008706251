import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-thumb-down',
    template: `
        <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height" viewBox="0 0 18 16" fill="none">
            <path d="M13.244 8.7722C13.244 9.44942 13.793 9.99842 14.4702 9.99842L16.0082 9.99842C16.6854 9.99842 17.2344 9.44942 17.2344 8.7722L17.2344 1.22622C17.2344 0.548997 16.6854 -4.79948e-08 16.0082 -1.072e-07L14.4702 -2.41649e-07C13.793 -3.00854e-07 13.244 0.548997 13.244 1.22622L13.244 8.7722Z" [attr.fill]="thumbsColor"/>
            <path d="M9.87698 12.105L9.87698 15.5174C9.87698 15.7839 9.66092 16 9.39439 16L9.05164 16C7.68417 16 6.57561 14.8914 6.57561 13.524L6.57561 9.2438L3.44763 9.2438C1.65441 9.2438 0.313388 7.59711 0.676515 5.84104L1.39273 2.37744C1.66447 1.06328 2.82188 0.120711 4.16384 0.120711L11.2918 0.120712C11.5798 0.120712 11.8527 0.185236 12.0969 0.300618C11.9849 0.587509 11.9235 0.899688 11.9235 1.22622L11.9235 8.7722C11.9235 8.96852 11.9457 9.15965 11.9877 9.34322C10.7716 9.67289 9.87698 10.7845 9.87698 12.105Z" [attr.fill]="thumbsColor"/>
        </svg>
    `,
})
export class ThumbDownSvgComponent {
    @Input() thumbsColor: string = '#31405A';
    @Input() height: string = '16px';
    @Input() width: string = '18px';
}
