import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-close-svg',
    template: `
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="close">
                <path id="Vector" d="M11.5293 3.52924C11.7896 3.2689 12.2117 3.2689 12.4721 3.52924C12.7324 3.7896 12.7324 4.2117 12.4721 4.47206L8.94345 8.00065L12.4721 11.5293C12.7324 11.7896 12.7324 12.2117 12.4721 12.4721C12.2117 12.7324 11.7896 12.7324 11.5293 12.4721L8.00065 8.94345L4.47206 12.4721C4.2117 12.7324 3.7896 12.7324 3.52924 12.4721C3.2689 12.2117 3.2689 11.7896 3.52924 11.5293L7.05785 8.00065L3.52924 4.47206C3.2689 4.2117 3.2689 3.7896 3.52924 3.52924C3.7896 3.2689 4.2117 3.2689 4.47206 3.52924L8.00065 7.05785L11.5293 3.52924Z" [attr.fill]="closeColor"/>
            </g>
        </svg>
    `,
})
export class CloseSvgComponent {
    @Input() closeColor: string = '#FFFFFF';
}
