import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";

import { ConfigService } from 'app/services/config.service';
import { LanguageService} from 'app/services/language.service';
import { AvatarInteractionService } from 'app/services/avatar-interaction.service';
import { ComponentAbstract } from 'app/components/component.abstract';
import { MessagesService } from "app/services/messages.service";
import { InteractionService } from "app/services/interaction.service";
@Component({
    selector: 'app-avatar-shortcuts',
    templateUrl: './avatar-shortcuts.component.html',
    styleUrl: './avatar-shortcuts.component.scss'
})
export class ShortcutsComponent extends ComponentAbstract implements OnInit, OnDestroy {
    isVisible: boolean = false;
    shortcutsList: any[] = [];
    textDirection!: string;
    isInteractionAllowed: boolean = false;

    languageSubscription!: Subscription;
    visibilitySubscription!: Subscription;
    interactionSubscription!: Subscription;

    constructor(
        config: ConfigService,
        private language: LanguageService,
        private visibility: AvatarInteractionService,
        private message: MessagesService,
        private interaction: InteractionService)
    {
        super(config);
    }

    ngOnInit() {
        this.visibilitySubscription = this.visibility.getVisibility('avatar-shortcuts').subscribe(visible => {
            this.isVisible = visible;
            if (this.isVisible) {
                ["avatar-drag-drop", "avatar-feedback"].map(key => this.visibility.hideComponent(key));
            }
        });

        this.languageSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            this.textDirection = this.config.getDirectionFromLanguage(selectedLanguage.locale)
            this.shortcutsList = this.config.getShortcuts( selectedLanguage.locale );
        });

        this.interactionSubscription = this.interaction.getInteractionMode().subscribe(interactionAllowed => {
            this.isInteractionAllowed = interactionAllowed;
        });
    }

    async onShortcutSubmit(value: string) {
        ["avatar-shortcuts", "avatar-drag-drop", "avatar-feedback"].map(key => this.visibility.hideComponent(key));
        await this.message.createConversationRequest("text", value);
    }

    ngOnDestroy() {
        this.visibilitySubscription.unsubscribe();
        this.interactionSubscription.unsubscribe();
    }
}
